<template>
  <div v-if="authorizedAccess">
    <v-container class="fill-height">
      <v-row justify="space-between" class="gradients white--text">
        <v-col v-for="option in options" :key="option.category">
          <v-btn
            small
            text
            dark
            :color="option.selected ? $store.state.secondaryColor : null"
            @click="reRoute(option.route)"
          >
            <v-icon x-small left>{{ option.icon }}</v-icon>
            <span class="caption text-capitalize">{{ option.category }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col cols="12">
          <router-view></router-view>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" persistent max-width="390">
        <v-card class="bkg" dark>
          <v-card-title class="headline gradients justify-center white--text"
            ><v-icon left medium color="white" size="40">info</v-icon> NOTICE
            !!!</v-card-title
          >
          <v-card-text class="mt-3 text-justify px-10"
            >This module is under development! Contact System Admin for further
            operations</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="$store.state.primaryColor"
              text
              @click="dialog = false"
              >ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
  <div class="fill-height" v-else>
    <UnauthorizedAccess :user="user" />
  </div>
</template>

<script>
import jwtdecode from "jwt-decode";
import UnauthorizedAccess from "@/components/mijini/UnauthorizedAccess";
export default {
  components: {
    UnauthorizedAccess,
  },
  data() {
    return {
      dialog: false,
      authorizedAccess: false,
    };
  },
  mounted() {
    this.checkLink();
  },
  computed: {
    options() {
      return [
        {
          icon: "fas fa-lock",
          category: this.$t("system"),
          route: "SystemGeneral",
          selected: true,
        },
        {
          icon: "fas fa-wallet",
          category: this.$t("accounting"),
          route: "Accounting",
          selected: false,
        },

        {
          icon: "fas fa-wallet",
          category: this.$t("payroll"),
          route: "payrollSettings",
          selected: false,
        },
        {
          icon: "fas fa-euro-sign",
          category: this.$t("currency"),
          route: "Currency",
          selected: false,
        },
        {
          icon: "fas fa-credit-card",
          category: this.$t("credit card details"),
          route: "pro-p",
          selected: false,
        },
        {
          icon: "fas fa-key",
          category: this.$t("license"),
          route: "License",
          selected: false,
        },
        {
          icon: "fas fa-question",
          category: this.$t("help"),
          route: "pro-h",
          selected: false,
        },
      ];
    },
  },
  watch: {
    $route() {
      this.checkLink();
    },
  },

  created() {
    this.getUser();
  },

  methods: {
    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      if (!this.user.accessLevel.isEmployee) this.authorizedAccess = true;
      else this.authorizedAccess = false;
    },

    reRoute(link) {
      if (link.includes("pro")) {
        this.dialog = true;
      } else {
        this.$router.push({ name: link });
      }
    },

    checkLink() {
      var link = this.$route.path;
      this.options.forEach((val) => (val.selected = false));
      var i = this.options.findIndex((x) => "/settings/" + x.route == link);
      this.options[i].selected = true;
    },
  },
};
</script>

<style scoped></style>
